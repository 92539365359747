body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mint-app {
  display: flex;
  flex-direction: column;
}

.home {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.more-page {
  display: flex;
  flex-direction: column;
  gap: 0px
}

/**  Navigation and Menu Bar */
.top-nav {
  display: flex;
  width: calc(100vw - 240px);
  height: 80px;
  padding: 0px 120px 0px 120px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.logo {
  display: flex;
  height: 24px;
  width: 136px;
}

.learn-more {
  /* Learn everything about us ! */
  height: 24px;

  /* Base - Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.004em;
  text-align: center;
  color: #00000000;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
}

.power-by {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.caurgette {
  font-family: "Courgette", sans-serif !important;
}

.clash-display {
  font-family: "ClashDisplay-Semibold", sans-serif;
}

p {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -.4px;
  line-height: 24px;
}

h2 {
  font-size: 44px;
  font-weight: 400;
  font-style: normal;
  line-height: 48px;
  letter-spacing: 0px;
}

h3 {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.4px;
  line-height: 40px;
}

h4 {
  margin: 0px;
  vertical-align: middle;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -.1px;
  line-height: 24px;
}

.nav-action {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /*gap: 24px;*/

  margin: 0 auto;
  width: 271px;
  height: 48px;


  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
}

.nav-action>.contact-us {
  /* Contact US */
  width: 134px;
  height: 48px;

  /* Base - Medium */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* Identical to box height, or 150% */
  letter-spacing: -0.004em;
  color: #000000;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.nav-action>.our-launch {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  text-align: center;

  width: 137px;
  height: 48px;

  background: rgb(2, 9, 29);
  border-radius: 28px;
  color: #fff;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.summit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 36px;
  opacity: 1;
  height: 48px;
  max-width: 138px;
  color: #fff;
}

.ext-width {
  max-width: 200px;
}

.border-1 {
  border-color: rgb(21, 171, 143);
}

.border-2 {
  border-color: rgb(71, 73, 77);
}


.border-3 {
  border-color: rgb(71, 73, 77);
}

.border-4 {
  border-color: rgb(237, 139, 111);
}

.border-5 {
  border-color: rgb(21, 171, 143);
}

.border-6 {
  border-color: rgb(128, 91, 240);
}

.border-7 {
  border-color: rgb(71, 73, 77);
}



@media screen and (max-width: 800px) {

  .home {
    gap: 48px;
  }

  .top-nav {
    padding: 0px 60px 0px 60px;
  }

  .nav-action {
    display: none;
  }

  .learn-more {
    display: none;
  }
}

/** Home title */

.home-title {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.home-title-child {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 1200px) {
  .home-title-child {}

  .home-title-child>img {
    width: 80%;
    height: auto;
  }
}

.card-content-center {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.card-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.open-action {
  float: right;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 10;

  transition: transform 0.5s;
  transform: rotate(0deg) translateZ(0);
}

.open-action:hover {
  transition: transform 0.5s;
  transform: rotate(45deg) translateZ(0);
}

.white-font {
  color: #fff;
}

.black-font {
  color: #000;
}

.center-card {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card-content-holder {
  padding: 24px;
  /*transition: transform .2s;
  transform: scale(1);*/
}

.content-slider {
  width: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: ease 1s;
}

.content-slider-1 {
  transform: translateX(0%);
}

.content-slider-2 {
  transform: translateX(-100%);
}

.content-slider-3 {
  transform: translateX(-200%);
}

.content-slider-4 {
  transform: translateX(-300%);
}

.slider {
  flex: 0 0 100%;
  width: inherit;
  scroll-snap-align: start;
}


.card-content-holder:hover {
  transition: transform .5s;
  transform: scale(1.005);
}

/**  Bento Box */
.bento-tile-1 {
  border-radius: 56px;
  background-color: #B9F1E7;
  grid-column: auto / span 4;
  grid-row: auto / span 6;

  width: 100%;
  height: 100%;
  order: 0;
}

.bento-tile-2 {
  border-radius: 56px;
  background-color: #F6F6F6;
  grid-column: auto / span 5;
  grid-row: auto / span 3;

  width: 100%;
  height: 100%;
  order: 1;
}

.bento-tile-3 {
  border-radius: 56px;
  background-color: #AD92FF;
  grid-column: auto / span 3;
  grid-row: auto / span 3;

  overflow: hidden;
  width: 100%;
  height: 100%;
  order: 2;
}

.bento-tile-4 {
  border-radius: 56px;
  background-color: #FFDED5;
  grid-column: auto / span 4;
  grid-row: auto / span 5;

  width: 100%;
  height: 100%;
  order: 3;
}

.bento-tile-5 {
  border-radius: 56px;
  background-color: #B9F1E7;
  grid-column: auto / span 4;
  grid-row: auto / span 5;

  width: 100%;
  height: 100%;
  order: 4;
}

.bento-tile-6 {
  border-radius: 56px;
  background-color: #E3D9FF;
  grid-column: auto / span 4;
  grid-row: auto / span 6;

  width: 100%;
  height: 100%;
  order: 5;
}

.bento-tile-7 {
  border-radius: 56px;
  background-color: #252525;
  grid-column: auto / span 4;
  grid-row: auto / span 4;

  width: 100%;
  height: 100%;
  order: 6;
}

.bento-tile-8 {
  border-radius: 56px;
  background-color: #6F65F6;
  grid-column: auto / span 4;
  grid-row: auto / span 4;

  width: 100%;
  height: 100%;
  order: 7;
}

.bento-tile-7.active {
  grid-column: auto / span 8;
  grid-row: auto / span 9;
  order: 4;
}

.bento-tile-8.active {
  grid-column: auto / span 8;
  grid-row: auto / span 9;
  order: 4;
}

.bento-tile-4.hidden {
  display: none;
}

.bento-tile-5.hidden {
  display: none;
}

.bento-tile-7.hidden {
  display: none;
}

.bento-tile-8.hidden {
  display: none;
}

.bento-grid-holder {
  display: grid;
  justify-content: center;
  width: calc(100vw - 48px);
  gap: 24px;
  margin: 24px;
  grid-auto-rows: minmax(0, 1fr);
  grid-template-columns: repeat(12, minmax(50px, 1fr));
  grid-template-rows: repeat(12, minmax(0, 1fr));
  min-height: 1080px;
}

.card-expanded-summit {
  margin: 56px;
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fill, minmax(1px, 1fr));
}

.mint-summit-holder {
  grid-column: 1/ -1;
  height: auto;
}

.mint-summit-content {
  padding: 20px;
  grid-column: 1/ -1;
  height: auto;
}

.mint-summit {
  width: 100%;
  height: auto;
  border-radius: 56px;
}

@media (min-width: 810px) and (max-width: 1279px) {

  .bento-grid-holder {
    grid-template-columns: repeat(8, minmax(50px, 1fr));
    min-height: 1580px;
  }

  .bento-tile-1 {
    order: 0;
  }

  .bento-tile-2 {
    grid-row: auto / span 4;
    order: 2;
  }

  .bento-tile-3 {
    grid-row: auto / span 4;
    order: 3;
  }

  .bento-tile-4 {
    order: 5;
  }

  .bento-tile-5 {
    order: 6;
  }

  .bento-tile-6 {
    order: 1;
  }

  .bento-tile-7 {
    order: 6;
  }

  .bento-tile-8 {
    order: 7;
  }

}

.iit-logo {
  height: 200px;
  width: 200px;
}

@media (max-width: 809px) {


  .bento-grid-holder {
    gap: 16px;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(1px, 1fr));
  }

  .iit-logo {
    height: 120px;
    width: 120px;
  }

  .iit-font {
    font-size: 28px;
  }

  .iit-font1 {
    font-size: 40px;
  }

  .bento-tile-1 {
    grid-column: 1 / -1;
    height: 386px;
    order: 0;
  }

  .bento-tile-2 {
    grid-column: 1 / -1;
    height: 184px;
    order: 2;
  }

  .bento-tile-3 {
    grid-column: 1 / -1;
    height: 184px;
    order: 3;
  }

  .bento-tile-4 {
    grid-column: 1 / -1;
    height: 1fr;
    order: 5;
  }

  .bento-tile-5 {
    grid-column: 1 / -1;
    height: 1fr;
    order: 6;
  }

  .bento-tile-6 {
    grid-column: 1 / -1;
    height: 386px;
    order: 1;
  }

  .bento-tile-7 {
    grid-column: 1 / -1;
    height: 1fr;
    order: 6;
  }

  .bento-tile-8 {
    grid-column: 1 / -1;
    height: 1fr;
    order: 7;
  }

  .bento-tile-7.active {
    grid-column: 1 / -1;
    order: 7;
  }

  .bento-tile-8.active {
    grid-column: 1 / -1;
    order: 8;
  }

}

/** More Page */
.what-mint-card-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 60px 120px 100px 120px;
  gap: 64px;
}

.what-mint-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: end;
  justify-items: end;
  height: 402px;
  width: 100%;
  padding: 56px 44px 56px 44px;
  gap: 72px;
  border-radius: 88px;
  background-color: #02091D;
}

.what-mint-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 44px;

  width: 420px;
  height: 92px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.logo-color-3 {
  color: #1BD9B5;
}

.logo-color-2 {
  color: #A185F8;
}

.logo-color-1 {
  color: #FFB4A0;
}

.what-mint-card-border {
  width: 113px;
  height: 0px;

  border: 1px solid #47494D;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.what-mint-card-content-text {
  width: 420px;
  height: 48px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.004em;

  color: #FFFFFF;


  flex: none;
  order: 1;
  flex-grow: 0;
}

.what-mint-card-descrip {
  width: 719px;
}

.what-mind-card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  gap: 21px;

  width: 100%;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.what-mind-card-grid-1 {
  border-radius: 48px;
  width: calc(calc(100% - 42px) / 3);
  height: 280px;
  background: #C6F8EF;
}

.what-mind-card-grid-2 {
  border-radius: 48px;
  width: calc(calc(100% - 42px) / 3);
  height: 280px;
  background: #E3D9FF;
}

.what-mind-card-grid-3 {
  border-radius: 48px;
  width: calc(calc(100% - 42px) / 3);
  height: 280px;
  background: #FFDED5;
}

.why-mint-card-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0px 120px 0px 120px;
  gap: 32px;
}

.why-mint-card-content {
  display: flex;
  flex-direction: column;
  width: 719px;
}

.why-back {
  background: #E3D9FF;
}

.how-mint-card-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 140px 120px 80px 120px;
  gap: 32px;
  position: relative;
}

.car-img {
  float: right;
  position: absolute;
  top: -75px;
  right: 0;
  z-index: -1;
}

.how-mint-card-content {
  display: flex;
  flex-direction: column;
  width: 719px;
}

.how-back {
  background: #07BDA24D;
  ;
}

.how-mint-img {
  width: 100%;
  height: auto;
}

.how-grid {
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(50px, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

@media (min-width: 810px) and (max-width: 1279px) {
  .how-grid {
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
    min-height: 1580px;
  }
}

.contact-us-expand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  gap: 20px;
}

.hand-cursor {
  cursor: pointer;
}

.contact-holder {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
}

.profile {
  width: 100%;
  height: auto;
  max-width: 200px;
  border-radius: 25px;
}

.contact-content {}

.how-grid-1 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 1;
  background: #15AB8F26;
  border-radius: 32px;
}

.how-grid-2 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 2;
  background: #15AB8F26;
  border-radius: 32px;
}

.how-grid-3 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 3;
  border-radius: 32px;
}

.how-grid-4 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 4;
  border-radius: 32px;
}

.how-grid-5 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 5;
  background: #15AB8F26;
  border-radius: 32px;
}

.how-grid-6 {
  grid-column: auto / span 1;
  grid-row: auto / span 1;
  order: 6;
  background: #15AB8F26;
  border-radius: 32px;
}

.how-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-items: center;
  justify-content: center;
}

.who-mint-card-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 0px 120px 0px 120px;
  gap: 32px;
}

.who-mint-card-content {
  display: flex;
  flex-direction: column;
  width: 719px;
}


.who-mind-card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  gap: 21px;

  width: 100%;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.who-mind-card-grid-1 {
  width: calc(calc(100% - 42px) / 3);
  height: auto;
}

.who-mind-card-grid-2 {
  width: calc(calc(100% - 42px) / 3);
  height: auto;
}

.who-mind-card-grid-3 {
  width: calc(calc(100% - 42px) / 3);
  height: auto;
}

.join-mint-card-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 20px 120px 100px 120px;
  gap: 32px;
}

.join-mint-card-content {
  display: flex;
  flex-direction: column;
  width: 719px;
}

.join-mind-card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px;
  gap: 21px;

  width: 100%;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.join-mind-card-grid-1 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #FFDED5;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.join-mind-card-grid-2 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #15AB8F26;
}

.join-mind-card-grid-3 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #15AB8F26;
}

.join-mind-card-grid-4 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #15AB8F26;
}

.join-mind-card-grid-5 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #15AB8F26;
}

.join-mind-card-grid-6 {
  width: calc(calc(100% - 42px) / 3);
  height: 350px;
  border-radius: 32px;
  background: #E3D9FF;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

@media (min-width: 810px) and (max-width: 1279px) {
  .what-mint-card-holder {
    padding: 60px 80px 100px 80px;
  }

  .why-mint-card-holder {
    padding: 0px 80px 0px 80px;
  }

  .how-mint-card-holder {
    padding: 140px 80px 80px 80px;
  }

  .who-mint-card-holder {
    padding: 0px 80px 0px 80px;
  }

  .join-mint-card-holder {
    padding: 20px 80px 100px 80px;
  }

  .join-mind-card-grid-1 {
    width: 100%;
  }

  .join-mind-card-grid-2 {
    width: 100%;
  }

  .join-mind-card-grid-3 {
    width: 100%;
  }

  .join-mind-card-grid-4 {
    width: 100%;
  }

  .join-mind-card-grid-5 {
    width: 100%;
  }

  .join-mind-card-grid-6 {
    width: 100%;
  }
}

@media (max-width: 809px) {

  .what-mint-card-holder {
    padding: 60px 20px 100px 20px;
  }

  .why-mint-card-holder {
    padding: 0px 20px 0px 20px;
  }

  .how-mint-card-holder {
    padding: 140px 20px 80px 20px;
  }

  .who-mint-card-holder {
    padding: 0px 20px 0px 20px;
  }

  .join-mint-card-holder {
    padding: 20px 20px 100px 20px;
  }

  .join-mind-card-grid-1 {
    width: 100%;
  }

  .join-mind-card-grid-2 {
    width: 100%;
  }

  .join-mind-card-grid-3 {
    width: 100%;
  }

  .join-mind-card-grid-4 {
    width: 100%;
  }

  .join-mind-card-grid-5 {
    width: 100%;
  }

  .join-mind-card-grid-6 {
    width: 100%;
  }

  .who-mind-card-grid-1 {
    width: 100%;
  }

  .who-mind-card-grid-2 {
    width: 100%;
  }

  .who-mind-card-grid-3 {
    width: 100%;
  }

  .what-mind-card-grid-1 {
    width: 100%;
  }

  .what-mind-card-grid-2 {
    width: 100%;
  }

  .what-mind-card-grid-3 {
    width: 100%;
  }

  .what-mint-card-descrip {
    width: 90%
  }

  .why-mint-card-content {
    width: 90%
  }

  .how-mint-card-content {
    width: 90%
  }

  .join-mint-card-content {
    width: 90%;
  }

  .who-mint-card-content {
    width: 90%;
  }

  .how-grid-1 {
    grid-column: 1 / -1;
  }

  .how-grid-2 {
    grid-column: 1 / -1;
  }

  .how-grid-3 {
    grid-column: 1 / -1;
  }

  .how-grid-4 {
    grid-column: 1 / -1;
  }

  .how-grid-5 {
    grid-column: 1 / -1;
  }

  .how-grid-6 {
    grid-column: 1 / -1;
  }
}